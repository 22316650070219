import React from 'react';
import './footer.scss';

function Footer() {

  return (
    <div className="footer">
      <p className="footer-copyright">ⓒ 2020. Syeon. all rights reserved.<br />
        Programmed by. Enif</p>
    </div>
  );
}

export default Footer;
